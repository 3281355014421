import { Fragment, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import {
  useDeleteHomeGroupMember,
  useFetchHomeGroupMembers,
  usePostHomeGroupMember,
} from "src/data/homeGroups/queries/homeGroupMemberQueries"
import { IHomeGroupMember } from "src/data/homeGroups/types/homeGroupTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchMembers } from "src/data/organizations/queries/memberQueries"
import { IOrganizationMember } from "src/data/organizations/types/organizationMemberTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MBanner } from "src/ui/MBanner/MBanner"
import { FullscreenView } from "src/ui/Modals/FullscreenView"
import { Heading2Mixin, MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { debug } from "src/utils/logger"

const MEMBER_PAGE_SIZE = 10

export function HomeGroupAddExistingMembers({
  homeGroupId,
}: {
  homeGroupId: string
}) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const { limit, setOffset, offset } = useUrlPager({
    initialLimit: MEMBER_PAGE_SIZE,
  })
  const { org } = useOrganization()
  const [busyMemberId, setBusyMemberId] = useState("")
  const deleteHomeGroupMember = useDeleteHomeGroupMember()
  const postHomeGroupMember = usePostHomeGroupMember()

  const fetchHomegroupMembers = useFetchHomeGroupMembers({
    orgId: org.id,
    homeGroupId: homeGroupId,
  })
  const homeGroupMembers = fetchHomegroupMembers.data?.members || []

  const fetchMembers = useFetchMembers({
    orgId: org.id,
    filter: { offset, limit },
  })
  const orgMembers = fetchMembers.data?.members || []

  function handleAddMemberClick(id: string) {
    setBusyMemberId(id)
    postHomeGroupMember.mutate(
      { homeGroupId, homeGroupMemberId: id, orgId: org.id },
      { onSettled: () => setBusyMemberId("") }
    )
  }

  function handleRemoveMemberClick(homeGroupMemberId: string) {
    setBusyMemberId(homeGroupMemberId)
    deleteHomeGroupMember.mutate(
      { orgId: org.id, homeGroupId, homeGroupMemberId },
      {
        onSettled() {
          setBusyMemberId("")
        },
      }
    )
  }

  const rows: JSX.Element[] = orgMembers.map((member) => (
    <Fragment key={member.member_id}>
      <MText variant="subtitle">{member.email}</MText>

      <ShowAddMemberAction
        key={member.member_id}
        orgMember={member}
        currentHomeGroupMembers={homeGroupMembers}
        onAddMember={handleAddMemberClick}
        onRemoveMember={handleRemoveMemberClick}
        loading={busyMemberId === member.member_id}
      />
    </Fragment>
  ))

  if (fetchMembers.isError || fetchHomegroupMembers.isError) {
    return (
      <MBanner type="error">{t(langKeys.failed_something_went_wrong)}</MBanner>
    )
  }

  return (
    <AddHomeBox>
      <Title>{t(langKeys.organizations_homegroups_member_select_title)}</Title>

      {!homeGroupId ? (
        <MBanner type="error">
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      ) : (
        <GridTable
          rows={rows}
          templateColumns="2fr auto"
          loading={fetchMembers.isLoading || fetchHomegroupMembers.isLoading}
          useFallbackResponsiveMode
        />
      )}
      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchMembers.data?.paging?.total_count || 0}
        />
      </PagerWrapper>
      <ActionArea $addTopMargin={rows.length <= limit}>
        <MButton
          onClick={() =>
            navigate({
              pathname: Routes.HomeGroupDetails.path(homeGroupId),
              hash: Routes.HomeGroupDetails.tabs[1],
            })
          }
        >
          {t(langKeys.done)}
        </MButton>
      </ActionArea>
    </AddHomeBox>
  )
}

function ShowAddMemberAction({
  orgMember,
  currentHomeGroupMembers,
  onAddMember,
  onRemoveMember,
  loading,
}: {
  orgMember: IOrganizationMember
  currentHomeGroupMembers: IHomeGroupMember[]
  onAddMember: (homeId: string, homeName: string) => void
  onRemoveMember: (homeId: string) => void
  loading?: boolean
}) {
  const { t, langKeys } = useTranslate()

  const currentHgMember = currentHomeGroupMembers.find(
    (hgMember) => hgMember.email === orgMember.email
  )

  if (currentHgMember) {
    return (
      <div key={orgMember.member_id}>
        <StyledButtonBox>
          <MButton
            size="small"
            loading={loading}
            onClick={() => {
              onRemoveMember(currentHgMember.member_id)
              debug.log("removing", orgMember)
            }}
            variant="secondary"
          >
            {t(langKeys.remove)}
          </MButton>
        </StyledButtonBox>
      </div>
    )
  }

  return (
    <div key={orgMember.user_id}>
      <StyledButtonBox>
        <MButton
          size="small"
          loading={loading}
          onClick={() => onAddMember(orgMember.member_id, orgMember.name)}
        >
          {t(langKeys.add)}
        </MButton>
      </StyledButtonBox>
    </div>
  )
}

const Title = styled.div`
  ${Heading2Mixin};
`

const AddHomeBox = styled.div`
  margin: auto;
`

const ActionArea = styled.div<{ $addTopMargin: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => (props.$addTopMargin ? spacing.XL : 0)};
`

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

export function HomeGroupAddExistingMembersRoute() {
  const { navigate } = useRouter()

  const { homeGroupId = "" } = useParams<{ homeGroupId?: string }>()
  return (
    <FullscreenView
      onClose={() =>
        navigate({
          pathname: Routes.HomeGroupDetails.path(homeGroupId),
          hash: Routes.HomeGroupDetails.tabs[1],
        })
      }
    >
      <HomeGroupAddExistingMembers homeGroupId={homeGroupId} />
    </FullscreenView>
  )
}
